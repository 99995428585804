import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Roles = lazy(() => import('../../container/pages/roles/index'));
const RolesShow = lazy(() => import('../../container/pages/roles/show'));

function RoleRoutes() {
  return (
    <Routes>
      <Route index element={<Roles />} />
      <Route path=":id/*" element={<RolesShow />} />
    </Routes>
  );
}

export default RoleRoutes;
