import Cookies from 'js-cookie';
import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/v1/auth/login', values);
      if (!response.data.success) {
        dispatch(loginErr('Login failed'));
        return;
      }
      if (response.data.errors) {
        dispatch(loginErr(response.data.errors));
      } else {
        Cookies.set('access_token', response.data.token);
        Cookies.set('logedIn', true);
        const profile = await DataService.get('/v1/profile');
        if (profile.data.errors) {
          dispatch(loginErr(response.data.data.errors));
        } else {
          const user = {
            name: profile.data.data.name,
            lastname: profile.data.data.lastname,
            id: profile.data.data.id,
            avatar: profile.data.data.avatar,
            type: profile.data.data.type,
            currentOrgId: profile.data.data.current_organization_id,
          };
          Cookies.set('user', JSON.stringify(user));
          Cookies.set('currentOrganizationId', profile.data.data.current_organization_id);
        }
        const environment = await DataService.get('/v1/environment');
        if (environment.data.errors) {
          dispatch(loginErr(response.data.data.errors));
        } else {
          Cookies.set('environemnt', JSON.stringify(environment.data));
        }
        dispatch(loginSuccess(true));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/v1/auth/register', values);
      // console.log(response.data.success);
      if (response.data.success === true) {
        notification.success({
          message: 'Register',
          description: 'Register success.',
        });
        if (callback) {
          callback(); // Call the callback function to handle redirection
        }
      }
    } catch (err) {
      notification.error({
        message: 'Register',
        description: 'Register failed.',
      });
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      const token = Cookies.get('access_token');
      if (token) {
        const response = await DataService.post('/v1/auth/logout', {}, { Authorization: `Bearer ${token}` });
        if (response.data.success === true) {
          Cookies.remove('logedIn');
          Cookies.remove('access_token');
          Cookies.remove('user');
          dispatch(logoutSuccess(false));
          callback();
        } else {
          dispatch(logoutErr());
        }
      }
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register };
