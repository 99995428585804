import React from 'react';
import { Row, Col, Form, Input, Button, notification } from 'antd';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthFormWrap } from './style';
import { DataService } from '../../../../config/dataService/dataService';

function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { email } = useParams();
  const handleSubmit = async (values) => {
    try {
      values.token = token;
      values.email = email;
      const response = await DataService.post('/v1/auth/reset-password', values);
      if (response.data.success === true) {
        notification.success({
          message: 'Reset Password',
          description: 'The password has been changed successfully.',
        });
        setTimeout(() => {
          navigate('/home');
        }, 1000);
      }
    } catch (error) {
      notification.error({
        message: 'Reset Password',
        description: error.response.data.message,
      });
    }
  };

  const handleCancel = () => {
    navigate('/home');
  };

  return (
    <>
      <Helmet>
        <title>SpsNexus | Reset Password</title>
      </Helmet>
      <Row justify="center">
        <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
          <AuthFormWrap>
            <Form name="resetPass" onFinish={handleSubmit} layout="vertical">
              <div className="ninjadash-authentication-top">
                <h2 className="ninjadash-authentication-top__title">Reset Password</h2>
              </div>
              <div className="ninjadash-authentication-content">
                <Form.Item name="password" label="New Password">
                  <Input.Password />
                </Form.Item>
                <Form.Item name="password_confirmation" label="Confirm New Password">
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <div className="setting-form-actions">
                    <Button htmlType="submit" type="primary">
                      Change Password
                    </Button>
                    &nbsp; &nbsp;
                    <Button size="default" onClick={handleCancel} type="light">
                      Cancel
                    </Button>
                  </div>
                </Form.Item>
              </div>
            </Form>
          </AuthFormWrap>
        </Col>
      </Row>
    </>
  );
}

export default ResetPassword;
