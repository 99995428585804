import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { UilBright, UilBrightnessHalf } from '@iconscout/react-unicons';
import { changeLayoutMode } from '../../../redux/themeLayout/actionCreator';

const ModeSelector = React.memo(() => {
  const dispatch = useDispatch();
  const [isDarkMode, setIsDarkMode] = useState(false);

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };

  // Sayfa yüklenirken karanlık mod ayarını kontrol et
  useEffect(() => {
    const cookieMode = Cookies.get('isDarkMode');
    if (cookieMode) {
      const darkModeActive = cookieMode === 'darkMode';
      setIsDarkMode(darkModeActive);
      if (darkModeActive) {
        document.body.classList.add('dark-mode');
        dispatch(changeLayoutMode('darkMode'));
      } else {
        document.body.classList.remove('dark-mode');
        dispatch(changeLayoutMode('lightMode'));
      }
    }
  }, [dispatch]);

  const darkmodeActivated = () => {
    document.body.classList.add('dark-mode');
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove('dark-mode');
  };

  const toggleMode = () => {
    if (isDarkMode) {
      darkmodeDiactivated();
      changeLayout('lightMode');
      Cookies.set('isDarkMode', 'lightMode');
    } else {
      darkmodeActivated();
      changeLayout('darkMode');
      Cookies.set('isDarkMode', 'darkMode');
    }
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className="ninjadash-nav-actions__item ninjadash-nav-actions__message">
      <a href="#" onClick={toggleMode}>
        {isDarkMode ? <UilBright /> : <UilBrightnessHalf />}
      </a>
    </div>
  );
});

export default ModeSelector;
