import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Departments = lazy(() => import('../../container/pages/employee-departments/index'));

function EmployeeDepartmentRoutes() {
  return (
    <Routes>
      <Route index element={<Departments />} />
    </Routes>
  );
}

export default EmployeeDepartmentRoutes;
