import { Spin } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Pages from './pages';
import Organizations from './organizations';
import Roles from './roles';
import Employees from './employees';
import UserRoles from './userRoles';
import EmployeeAtrrbites from './employeeAttributes';
import EmployeeGroups from './employeeGroups';
import EmployeePositions from './employeePositions';
import EmployeeDepartments from './employeeDepartments';
import EmployeeCards from './employeeCards';
import VisitorCards from './visitorCards';
import AvailableCards from './availableCards';
import CardGroups from './cardGroups';
import CardUidTypes from './cardUidTypes';

import withAdminLayout from '../../layout/withAdminLayout';

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Pages />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="organizations/*" element={<Organizations />} />
        <Route path="/admin/roles/*" element={<Roles />} />
        <Route path="/roles/*" element={<UserRoles />} />
        <Route path="employees/*" element={<Employees />} />
        <Route path="employee-attributes/*" element={<EmployeeAtrrbites />} />
        <Route path="employee-groups/*" element={<EmployeeGroups />} />
        <Route path="employee-positions/*" element={<EmployeePositions />} />
        <Route path="employee-departments/*" element={<EmployeeDepartments />} />
        <Route path="employee-cards/*" element={<EmployeeCards />} />
        <Route path="visitor-cards/*" element={<VisitorCards />} />
        <Route path="available-cards/*" element={<AvailableCards />} />
        <Route path="employee-cards/*" element={<EmployeeCards />} />
        <Route path="card-groups/*" element={<CardGroups />} />
        <Route path="card-uid-types/*" element={<CardUidTypes />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
