import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Positions = lazy(() => import('../../container/pages/employee-positions/index'));

function EmployeePositionsRoutes() {
  return (
    <Routes>
      <Route index element={<Positions />} />
    </Routes>
  );
}

export default EmployeePositionsRoutes;
