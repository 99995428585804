import { UilSimCard, UilLayerGroup, UilUsersAlt, UilFile, UilPostcard } from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => ({
    topMenu: state.ChangeLayoutMode.topMenu,
  }));

  const path = '/';
  const pathName = window.location.pathname;
  const pathArray = pathName && pathName !== '/' ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  // Determine the selected key
  const selectedKey = `${
    mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
  }`;

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('application')}</NavTitle>,
      'app-title',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to="/organizations">
        {t('organizations')}
      </NavLink>,
      'organizations',
      !topMenu && (
        <NavLink className="menuItem-iocn" to="/organizations">
          <UilSimCard />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to="/employees/grid">
        {t('employees')}
      </NavLink>,
      'employees',
      !topMenu && (
        <NavLink className="menuItem-iocn" to="/employees/grid">
          <UilUsersAlt />
        </NavLink>
      ),
    ),
    getItem(t('cards'), 'cards', !topMenu && <UilPostcard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to="/employee-cards">
          {t('employee-cards')}
        </NavLink>,
        'employee-cards',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to="/available-cards">
          {t('available-cards')}
        </NavLink>,
        'available-cards',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to="/visitor-cards">
          {t('visitor-cards')}
        </NavLink>,
        'visitor-cards',
        null,
      ),
    ]),

    getItem(
      <NavLink onClick={toggleCollapsed} to="/roles">
        {t('roles')}
      </NavLink>,
      'user-roles',
      !topMenu && (
        <NavLink className="menuItem-iocn" to="/roles">
          <UilLayerGroup />
        </NavLink>
      ),
    ),
    getItem(t('definitions'), 'definitions', !topMenu && <UilFile />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to="/employee-attributes">
          {t('employee-definitions')}
        </NavLink>,
        'employee-definitions',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to="/employee-groups">
          {t('employee-groups')}
        </NavLink>,
        'employee-groups',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to="/employee-positions">
          {t('employee-positions')}
        </NavLink>,
        'employee-positions',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to="/employee-departments">
          {t('employee-departments')}
        </NavLink>,
        'employee-departments',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to="/card-groups">
          {t('card-groups')}
        </NavLink>,
        'card-groups',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to="/card-uid-types">
          {t('card-uid-types')}
        </NavLink>,
        'card-uid-types',
        null,
      ),
    ]),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('admin')}</NavTitle>,
      'app-title',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to="/admin/roles">
        {t('roles')}
      </NavLink>,
      'roles',
      !topMenu && (
        <NavLink className="menuItem-iocn" to="/admin/roles">
          <UilLayerGroup />
        </NavLink>
      ),
    ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      selectedKeys={[selectedKey]} // Set selectedKeys based on current path
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
