import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const CardGroups = lazy(() => import('../../container/pages/card-groups/index'));

function CardGroupRoutes() {
  return (
    <Routes>
      <Route index element={<CardGroups />} />
    </Routes>
  );
}

export default CardGroupRoutes;
