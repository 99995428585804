import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Roles = lazy(() => import('../../container/pages/userRoles/index'));
const RolesShow = lazy(() => import('../../container/pages/userRoles/show'));

function UserRoleRoutes() {
  return (
    <Routes>
      <Route index element={<Roles />} />
      <Route path=":id/*" element={<RolesShow />} />
    </Routes>
  );
}

export default UserRoleRoutes;
