import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Cards = lazy(() => import('../../container/pages/cards/employee-cards/index'));

function EmployeeCardRoutes() {
  return (
    <Routes>
      <Route index element={<Cards />} />
    </Routes>
  );
}

export default EmployeeCardRoutes;
