import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Employees = lazy(() => import('../../container/pages/employees/index'));
const EmployeeShow = lazy(() => import('../../container/pages/employees/show'));

function EmployeeRoutes() {
  return (
    <Routes>
      <Route path="/*" element={<Employees />} />
      <Route path="/show/:id/*" element={<EmployeeShow />} />
    </Routes>
  );
}

export default EmployeeRoutes;
