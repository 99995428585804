import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Cards = lazy(() => import('../../container/pages/cards/available-cards/index'));

function AvailableCardRoutes() {
  return (
    <Routes>
      <Route index element={<Cards />} />
    </Routes>
  );
}

export default AvailableCardRoutes;
