import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Cards = lazy(() => import('../../container/pages/cards/visitor-cards/index'));

function VisitorCardRoutes() {
  return (
    <Routes>
      <Route index element={<Cards />} />
    </Routes>
  );
}

export default VisitorCardRoutes;
