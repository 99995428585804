import React, { useState, useEffect } from 'react';
import { Select, notification } from 'antd';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { DataService } from '../../../config/dataService/dataService';

const OrganizationSelector = React.memo(() => {
  const [selectedValue, setSelectedValue] = useState(undefined);
  const [orgList, setOrgList] = useState([]);
  const user = JSON.parse(Cookies.get('user'));

  const handleSelectChange = async (value) => {
    setSelectedValue(value);
    const response = await DataService.put(`/v1/users/${user.id}`, { current_organization_id: value });
    if (response.data.success) {
      Cookies.set('currentOrganizationId', response.data.data.current_organization_id);
      window.location.reload();
    } else {
      notification.error({
        message: 'Error',
        description: response.data.message,
      });
    }
  };

  const getOrgList = async () => {
    const response = await DataService.get(`/v1/organizations?all=1`);
    if (response.data.success) {
      setOrgList(response.data.data);
      // Check if there's a currentOrganizationId in cookies and set it as selected value
      const currentOrgId = Cookies.get('currentOrganizationId');
      if (currentOrgId) {
        setSelectedValue(currentOrgId);
      }
    }
  };

  useEffect(() => {
    getOrgList();
  }, []);

  // Filter function to control which options are displayed when searching
  const filterOption = (input, option) => option.children.toLowerCase().includes(input.toLowerCase());

  return (
    <div className="ninjadash-searchbar">
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__searchbar">
        <Select
          placeholder="Select an organization"
          value={selectedValue}
          onChange={handleSelectChange}
          showSearch
          filterOption={filterOption}
          style={{ width: '250px' }}
        >
          {orgList.map((org) => (
            <Select.Option key={org.id} value={org.id}>
              {org.name}
            </Select.Option>
          ))}
        </Select>
        <Link to="/" className="ninjadash-close-icon">
          <UilTimes />
        </Link>
      </div>
    </div>
  );
});

export default OrganizationSelector;
