import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const CardUidTypes = lazy(() => import('../../container/pages/card-uid-types/index'));

function CardUidRoutes() {
  return (
    <Routes>
      <Route index element={<CardUidTypes />} />
    </Routes>
  );
}

export default CardUidRoutes;
