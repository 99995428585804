import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Groups = lazy(() => import('../../container/pages/employee-groups/index'));

function EmployeeGroupRoutes() {
  return (
    <Routes>
      <Route index element={<Groups />} />
    </Routes>
  );
}

export default EmployeeGroupRoutes;
