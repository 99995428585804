import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const BlankPage = lazy(() => import('../../container/pages/BlankPage'));
const Dashboard = lazy(() => import('../../container/pages/Dashboard'));
const Settings = lazy(() => import('../../container/profile/settings/Settings'));
function PagesRoute() {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path="starter" element={<BlankPage />} />
      <Route path="profile/*" element={<Settings />} />
    </Routes>
  );
}

export default PagesRoute;
