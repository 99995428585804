import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Attributes = lazy(() => import('../../container/pages/employee-attributes/index'));
// const RolesShow = lazy(() => import('../../container/pages/roles/show'));

function EmployeeAttributeRoutes() {
  return (
    <Routes>
      <Route index element={<Attributes />} />
      {/* <Route path=":id/*" element={<RolesShow />} /> */}
    </Routes>
  );
}

export default EmployeeAttributeRoutes;
