import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Organizations = lazy(() => import('../../container/pages/organizations/index'));
const OrganizationCreate = lazy(() => import('../../container/pages/organizations/create'));
const OrganizationShow = lazy(() => import('../../container/pages/organizations/show'));
const OrganizationUpdate = lazy(() => import('../../container/pages/organizations/update'));

function OrganizationRoutes() {
  return (
    <Routes>
      <Route index element={<Organizations />} />
      <Route path=":id/*" element={<OrganizationShow />} />
      <Route path="create" element={<OrganizationCreate />} />
      <Route path=":id/edit" element={<OrganizationUpdate />} />
    </Routes>
  );
}

export default OrganizationRoutes;
